import { Component, ViewEncapsulation, OnInit, ViewChild, HostListener } from '@angular/core'
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../app.service'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { IfStmt } from '@angular/compiler';
import { allowedNodeEnvironmentFlags } from 'process';
// import { FullComponent } from '../layouts/full/full.component'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./css.component.scss']
})
export class ProductComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {"bFilter": false};
  dtOptions: DataTables.Settings = {
    searching: false,
    responsive: true,
    pageLength: 50
  };


  dtTrigger: any = new Subject();

  closeResult: string
  files: any[]
  data: any = [

  ]

  product: any[]
  product2: any[]
  cart: any = [

  ]

  insert: any[] = [
    {
      //   do: null,
      //   id: null,
      brand_id: null,
      product_model_id: null,
      //   description: null,
      //   width: null,
      //   rim: null,
      //   series: null,
      //   load_index: null,
      //   OEM_marking: null,
      //   week: null,
      //   year: null,
      //   price: null,
      //   qty: null,
      //   remark: null,
      //   is_deleted: null,
      //   created_by: null,
      //   created_date: null,
      //   updated_by: null,
      //   updated_date: null

      width: null,
      series: null,
      rim: null,
      s_year: null,
      e_year: null,
      part_a: null,
      part_b: null
    }
  ]

  input_search: any[] = [
    {
      do: 'get_product_tire_search',
      brand_id: null,
      product_model_id: null,
      width: null,
      series: null,
      rim: null,
      width2: null,
      series2: null,
      rim2: null,
      s_year: null,
      e_year: null,
      part_a: null,
      part_b: null,
      check_two: false,
      group_id: 0,
    }
  ]

  type_product: any[]
  brand: any[]
  product_model: any[]
  select_model: any[]
  img_url: any[] = []
  data2: any[] = []
  select_data: any
  setting_code: any[]
  purchase_carts: any[]
  group_product_price: any[]
  category_id = 1
  check_unit = true;
  // check_two = true;
  customer_id = null
  loadding = true;
  group = 0
  innerWidth: any;
  use_discount: any[];
  product_image: any[];
  imageObject: Array<object> = [
    //     {
    //     image: 'assets/img/slider/1.jpg',
    //     thumbImage: 'assets/img/slider/1_min.jpeg',
    //     alt: 'alt of image',
    //     title: 'title of image'
    // }, {
    //     image: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
    //     thumbImage: '.../iOe/xHHf4nf8AE75h3j1x64ZmZ//Z==', // Support base64 image
    //     title: 'Image title', //Optional: You can use this key if want to show image with title
    //     alt: 'Image alt' //Optional: You can use this key if want to show image with alt
    // }
  ];

  constructor(
    private modalService: NgbModal,
    private dataService: DataService
  ) { }

  async ngOnInit() {
    await this.dataService.isLogIn()
    this.customer_id = this.dataService.login[0].customer_id
    this.group = this.dataService.login[0].group_id

    await this.get_brand(this.category_id)
    await this.get_product_model()
    await this.get_data()

    console.log('customer_id: ' + this.customer_id);

    if (this.customer_id) {
      await this.get_purchase_carts_customer();
    }

    // await this.get_use_discount(this.group,110);

    // if (this.group) {
    //   await this.get_group_product_price();
    // }

    this.dtOptions = {
      pagingType: 'full_numbers'
    };
    this.dtTrigger.next();

    this.innerWidth = window.innerWidth;
    console.log(this.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {

    if (this.dtElement.dtInstance === undefined) return
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      this.dtOptions.searching = false
      this.dtOptions.responsive = true
      // dtInstance.draw();
    });
  }

  clear() {
    this.product_model = []
    this.insert[0] = {}
  }

  async search_cancel() {
    this.product_model = []
    this.input_search[0] = {
      do: 'get_product_tire_search',
      brand_id: null,
      product_model_id: null,
      width: null,
      series: null,
      rim: null,
      width2: null,
      series2: null,
      rim2: null,
      s_year: null,
      e_year: null,
      part_a: null,
      part_b: null,
      check_two: false,
      group_id: 0
    }

    await this.get_data()
  }

  async opensubmodal(contentsubmodal, i) {

    this.modalService.open(contentsubmodal, { size: 'lg' })
    if (i != null) {
      await this.get_product_image(this.category_id, this.product[i].product_model_id)
      this.insert[0] = {
        product_id: this.product[i].id,
        product_code: this.product[i].code,
        product_model_id: this.product[i].product_model_id,
        category_id: this.category_id,
        customer_id: this.customer_id,
        qty: 1,
        // price: 0,
        // discount: 0,
        // discount_type: "P",
        // discount_actually_baht: 0,
        net_price: 0,
        priceperunit: 0,
        purchase_order_id: null,

        part_number: this.product[i].part_number,
        brand_name: this.product[i].brand_name,
        product_model_name: this.product[i].product_model_name,
        width: this.product[i].width,
        series: this.product[i].series,
        rim: this.product[i].rim,
        week: this.product[i].week,
        show_year: this.product[i].show_year,
        year: this.product[i].use_year,
        // product_url: this.product[i].product_url,
        product_url: this.product_image,
        show_price: this.product[i].show_price,
        price: this.product[i].price,
        discount_type: this.product[i].discount_type,
        discount: this.product[i].discount || 0,
        discount_actually_baht: this.product[i].discount_actually_baht
      }
      this.insert[0].do = 'add_purchase_carts'
      console.log(this.insert[0])
    }
  }

  closesubmodal(contentsubmodal) {
    let input = this.insert[0]
    let set = [
      'qty'
    ]

    for (let i = 0; i < set.length; i++) {
      if (input[set[i]] == null) {
        document.getElementById(set[i]).focus()
        alert('กรุณาระบุข้อมูลให้ครบ')
        return
      }
    }

    let pro = this.product.filter(
      item => item.id === input.product_id
    )

    if (parseInt(pro[0].qty) < parseInt(input.qty)) {
      alert("จำนวนสินค้าไม่เพียงพอ : " + pro[0].qty + " ชิ้น")
      return
    }

    // ******** Check ***********
    // let data = this.insert[0]
    var index = this.purchase_carts.findIndex(function (item, i) {
      return (
        item.product_code === input.product_code
        && item.customer_id === input.customer_id
        && item.purchase_order_id === null
      )
    })

    if (index > -1) {
      // alert('บันทึกไม่ได้ เนื่องจากข้อมูลซ้ำกัน')
      this.insert[0].do = 'edit_purchase_carts'
      this.insert[0].id = this.purchase_carts[index].id
    }

    this.insert[0].priceperunit = pro[0].price
    this.insert[0].price = pro[0].price * this.insert[0].qty
    this.insert[0].discount_actually_baht = this.insert[0].discount_actually_baht * this.insert[0].qty
    this.insert[0].net_price = this.insert[0].price - this.insert[0].discount_actually_baht


    console.log(this.insert[0])

    this.modalService.dismissAll()
    this.onSave()
  }


  // async check_discount(input) {
  //   let pro = this.product.filter(
  //     item => item.id === input
  //   )

  //   let dc = this.group_product_price.filter(
  //     item => (item.category_id === pro[0].category_id &&
  //       item.product_model_id === pro[0].product_model_id
  //       && (item.year === null ? item.year === null : item.year === pro[0].year))
  //   )

  //   this.insert[0].product_code = pro[0].code
  //   this.insert[0].price = pro[0].price

  //   if (dc.length > 0) {
  //     this.insert[0].discount_type = dc[0].discount_unit
  //     this.insert[0].discount = dc[0].discount
  //     this.insert[0].discount_actually_baht = dc[0].discount

  //     if (dc[0].discount_unit === 'N') this.insert[0].discount_actually_baht = dc[0].discount
  //     // else this.insert[0].discount_actually_baht = dc[0].discount / 100
  //     else this.insert[0].discount_actually_baht = pro[0].price * (dc[0].discount / 100)

  //   }

  //   // this.insert[0].price = pro[0].price * pro[0].qty

  // }

  // async get_use_discount(id,product_model_id) {
  //   console.log('use_discount id:' + id)

  //   await this.dataService.get_data({ do: 'use_discount' ,id: id ,product_model_id : product_model_id })
  //   this.use_discount = this.dataService.data

  //   console.log('this.use_discount')
  //   console.log(this.use_discount)
  // }

  save_img(event, i) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader()

      reader.readAsDataURL(event.target.files[0]) // read file as data url

      reader.onload = event => {
        // called once readAsDataURL is completed
        this.img_url[i] = event.target.result
      }

      this.insert[0].url_image[i] = event.target.files[0]
      this.insert[0].image[i] = event.target.files[0].name
    }
  }

  del_img(i) {
    this.img_url[i] = null
    this.insert[0].image[i] = null
    this.insert[0].url_image[i] = null
  }

  valueSelected(filterVal: any) {
    this.product_model = []
    if (filterVal == null) {
      this.insert[0].product_model_id = null
    }
    else {
      this.product_model = this.select_model.filter(
        item => item.brand_id === filterVal
      )
    }
  }

  valueSelected_part(filterVal: any) {
    this.check_unit = false
    if (filterVal == null) this.product = this.select_data
    else
      this.product = this.select_data.filter(item =>
        item.part_number.toLowerCase().match(filterVal.toLowerCase())
      )
    this.rerender()
  }

  // async onSearch() {
  //   if (this.check_unit == true) this.product = this.select_data.filter(
  //     item => item.qty > 0
  //   )
  //   else {
  //     this.product = this.select_data
  //   }

  //   if (this.insert[0].product_model_id != null) this.product = this.product.filter(
  //     item => item.product_model_id === this.insert[0].product_model_id
  //   )
  //   else if (this.insert[0].brand_id != null) this.product = this.product.filter(
  //     item => item.brand_id === this.insert[0].brand_id
  //   )

  //   this.rerender()
  // }

  async get_setting_code(e) {
    await this.dataService.get_data({ do: 'get_setting_code', category_id: e })
    this.setting_code = this.dataService.data
  }

  async get_brand(e) {
    await this.dataService.get_data({ do: 'get_brand', category_id: e })
    this.brand = this.dataService.data
  }

  async get_product_model() {
    await this.dataService.get_data({ do: 'get_product_model' })
    this.select_model = this.dataService.data
  }


  async get_product_image(category_id, product_model_id) {
    await this.dataService.get_data({ do: 'get_product_image_list', category_id, product_model_id: product_model_id })
    let res: any = this.dataService.data
    // console.log('res')
    // console.log(res)

    if (res[0] == 0) res = [{ alt: 'no image' }, { alt: 'no image' }, { alt: 'no image' }, { alt: 'no image' }]
    else {
      // res = res.map(x => x.url_image)

      res = res.map((x) => {
        return {
          image: this.dataService.host + x.url_image,
          thumbImage: this.dataService.host + x.url_image,
          // alt: 'alt of image',
          // title: 'title of image'
        }
      });
    }
    this.imageObject = res;
    // console.log('this.product_image')
    // console.log(this.imageObject)
  }

  async get_data() {
    this.loadding = true;
    this.input_search[0].do = 'get_product_tire_search'
    if (this.group != null) this.input_search[0].group_id = this.group

    // if ((this.input_search[0].s_year != null && this.input_search[0].e_year == null) || (this.input_search[0].s_year == null && this.input_search[0].e_year != null)) {
    //   alert("กรุณาระบุปีให้ครบ")
    //   return
    // }

    await this.dataService.get_data(this.input_search[0])
    this.select_data = this.dataService.data
    this.product = this.select_data

    if (this.product[0] == 0) this.product = []
    else {
      //this.product.forEach((item, index) => console.log(item[index]))
      // var temp_product = this.product;

      //set isMatch
      this.product.forEach((item) => item.isMatch = false);
      for (var i = 0; i < this.product.length; i++) {
        //Check two
        if (this.input_search[0].check_two) {
          //Check Choice one
          if (
            this.product[i].width == this.input_search[0].width &&
            this.product[i].series == this.input_search[0].series &&
            this.product[i].rim == this.input_search[0].rim &&
            this.product[i].isMatch == false
          ) {
            //Check Choice two
            if (i + 1 < this.product.length) {
              if (
                this.product[i].product_model_id == this.product[i + 1].product_model_id &&
                this.product[i + 1].width == this.input_search[0].width2 &&
                this.product[i + 1].series == this.input_search[0].series2 &&
                this.product[i + 1].rim == this.input_search[0].rim2 &&
                this.product[i + 1].isMatch == false
              ) {
                this.product[i].isMatch = true;
                this.product[i + 1].isMatch = true;
              }
            }
          }
          //Check Choice one
          else if (this.product[i].width == this.input_search[0].width2 &&
            this.product[i].series == this.input_search[0].series2 &&
            this.product[i].rim == this.input_search[0].rim2 &&
            this.product[i].isMatch == false
          ) {
            //Check Choice two
            if (i + 1 < this.product.length) {
              if (
                this.product[i].product_model_id == this.product[i + 1].product_model_id &&
                this.product[i + 1].width == this.input_search[0].width &&
                this.product[i + 1].series == this.input_search[0].series &&
                this.product[i + 1].rim == this.input_search[0].rim &&
                this.product[i + 1].isMatch == false
              ) {
                this.product[i].isMatch = true;
                this.product[i + 1].isMatch = true;
              }
            }
          }
        }
        //Discount
        if (this.product[i].discount > 0) {
          this.product[i].discount_actually_baht = this.product[i].price * (this.product[i].discount / 100)
          this.product[i].show_price = (this.product[i].price - this.product[i].discount_actually_baht).toFixed(0)
        }
        else {
          this.product[i].discount_actually_baht = this.product[i].discount
          this.product[i].show_price = (this.product[i].price - 0).toFixed(0)
        }
        this.product[i].discount_type = 'P';
      }


      // console.log('this.product')
      // console.log(this.product)
      // console.log(this.product.length)

    }

    this.loadding = false;

    this.rerender()

    // this.onSearch();
    //get_setting_code
    await this.get_setting_code(this.category_id)
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  async get_purchase_carts_customer() {
    await this.dataService.get_data({ do: 'get_purchase_carts_customer', id: this.customer_id })
    this.purchase_carts = this.dataService.data

    //purchase_order_id == null
    this.purchase_carts = this.purchase_carts.filter(
      item => item.purchase_order_id == null
    )
  }

  async get_group_product_price() {
    await this.dataService.get_data({ do: 'get_group_product_price', id: this.group })
    this.group_product_price = this.dataService.data
  }

  async get_image(id) {
    await this.dataService.get_data({
      do: 'get_product_tire_image',
      product_tire_id: id
    })
    this.product2 = this.dataService.data
  }

  async onSave() {
    await this.dataService.set_data(this.insert[0])
    // await this.onSaveMovement()
    this.get_purchase_carts_customer()
    // this.clear()
    // window.location.reload();

    //get noti
    await this.dataService.isLogIn()
  }

  async onSaveMovement() {
    //product_movement
    if (this.insert[0].qty_show != this.insert[0].qty) {
      this.insert[0].qty_show = this.insert[0].qty
      this.insert[0].do = "add_product_movement";
      await this.dataService.set_stock(this.insert[0]);
    }
  }

  async onDel(e) {
    if (confirm('ต้องการจะลบหรือไม่?')) {
      this.insert[0] = this.product[e]
      this.insert[0].do = 'del_product_tire'
      await this.dataService.set_data(this.insert[0])
      this.get_data()
      this.clear()
      window.location.reload();
    }
  }
}
