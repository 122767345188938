<!-- <app-full-layout></app-full-layout> -->

<!-- <div class="row">
  <div class="col-sm-12"> 
    <div class="card" >
      <div class="card-body">
        <img src="assets/images/img/top-form.png" alt="homepage" class="dark-logo"  />
      </div>
    </div>
  </div>
</div> -->

<app-navigation> </app-navigation>

<!-- 
<div class="row">
  <div class="col-sm-12"> 
    <div class="card" >
      <div class="card-body">
      </div>
    </div>



  </div>
</div> -->

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <!-- <div class="card-header">
        <div class="row" *ngFor="let item of insert">

          <div class="col-lg-1">
            <div class="ml-auto" style="margin-top: 8px;">
              <input type="checkbox" [(ngModel)]="check_unit">
              จำนวน > 0
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <select class="custom-select" [(ngModel)]="item.brand_id" (change)="valueSelected(item.brand_id)">
                <option [ngValue]="null"> *** เลือกแบรนด์สินค้า *** </option>
                <option *ngFor="let get of brand" [ngValue]="get.id">
                  {{ get.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <select class="custom-select" [(ngModel)]="item.product_model_id">
                <option [ngValue]="null">
                  *** เลือกชื่อรุ่นสินค้า ***
                </option>
                <option *ngFor="let get of product_model" [ngValue]="get.id">
                  {{ get.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <input type="search" class="form-control" [(ngModel)]="item.part_number"
                placeholder="*** Search part number ***" (change)="valueSelected_part(item.part_number)" />
            </div>
          </div>

          <div class="col-lg-2">
            <div class="d-md-flex align-items-left">
              <div class="ml-auto">
                <button style="margin-right:2px;" class="btn btn-sm btn-outline-success" (click)="onSearch()">
                  <i class="fa fa-search"></i>
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="card-header" *ngFor="let item of input_search">
        <div class="row">
          <div class="col-lg-3">
            <div class="ml-auto">
              <label> แบรนด์สินค้า</label>
              <select class="custom-select" [(ngModel)]="item.brand_id" (change)="valueSelected(item.brand_id)">
                <option [ngValue]="null">*** เลือกแบรนด์สินค้า ***</option>
                <option *ngFor="let get of brand" [ngValue]="get.id">
                  {{ get.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ชื่อรุ่นสินค้า</label>
              <select class="custom-select" [(ngModel)]="item.product_model_id">
                <option [ngValue]="null">*** เลือกชื่อรุ่นสินค้า ***</option>
                <option *ngFor="let get of product_model" [ngValue]="get.id">
                  {{ get.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto" style="margin-top: 8px">
              <input type="checkbox" [(ngModel)]="item.check_two" />
              ค้นหา 2 ขนาดพร้อมกัน
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <hr />
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ความกว้าง</label>
              <input type="number" class="form-control" [(ngModel)]="item.width" placeholder="235.." min="0"
                oninput="validity.valid||(value='');" id="width" />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ซีรีย์</label>
              <input type="number" class="form-control" [(ngModel)]="item.series" placeholder="45.." min="0"
                oninput="validity.valid||(value='');" id="series" />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ขนาดขอบล้อ</label>
              <input type="number" class="form-control" [(ngModel)]="item.rim" placeholder="19.." min="0"
                oninput="validity.valid||(value='');" id="rim" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <hr />
          </div>
        </div>

        <div class="row" *ngIf="item.check_two == true">
          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ความกว้าง</label>
              <input type="number" class="form-control" [(ngModel)]="item.width2" placeholder="235.." min="0"
                oninput="validity.valid||(value='');" id="width2" />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ซีรีย์</label>
              <input type="number" class="form-control" [(ngModel)]="item.series2" placeholder="45.." min="0"
                oninput="validity.valid||(value='');" id="series2" />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ขนาดขอบล้อ</label>
              <input type="number" class="form-control" [(ngModel)]="item.rim2" placeholder="19.." min="0"
                oninput="validity.valid||(value='');" id="rim2" />
            </div>
          </div>
        </div>

        <!-- <div class="row">
          <div class="col-lg-3">
            <div class="ml-auto">
              <label> จากปียาง </label>
              <input type="number" class="form-control" [(ngModel)]="item.s_year" placeholder="2000" min="0" max="9999"
                id="s_year" oninput="validity.valid||(value='');" />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> ถึงปียาง </label>
              <input type="number" class="form-control" [(ngModel)]="item.e_year" placeholder="2000" min="0" max="9999"
                id="e_year" oninput="validity.valid||(value='');" />

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-3">
            <div class="ml-auto">
              <label> Part Number A </label>
              <input type="text" class="form-control" [(ngModel)]="item.part_a" placeholder="2056016000320222" min="0"
                id="part_a" />
            </div>
          </div>

          <div class="col-lg-3">
            <div class="ml-auto">
              <label> Part Number B </label>
              <input type="text" class="form-control" [(ngModel)]="item.part_b" placeholder="2056016000320222" min="0"
                id="part_b" />
            </div>
          </div>
        </div> -->

        <div class="row">
          <div class="col-lg-6">
            <div *ngIf="loadding" class="ml-auto" style="text-align: right">
              <sa-spinner-circular></sa-spinner-circular>
            </div>

            <div *ngIf="loadding == false" class="ml-auto" style="text-align: right">
              <button style="margin-right: 2px" class="btn btn-sm btn-outline-success" (click)="get_data()">
                <i class="fa fa-search"></i>
                ค้นหา
              </button>
              <button style="margin-right: 2px" class="btn btn-sm btn-outline-danger" (click)="search_cancel()">
                <!-- <i class="fa fa-x"></i> -->
                X ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div class="table-responsive m-t-40">
          <table datatable name="myTable" id="myTable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="row-border hover">
            <thead style="font-size: 15px">
              <tr>
                <!-- <th>รหัสสินค้า</th>
                <th>Part-Number</th> -->
                <th class="{{ innerWidth < 900 ? 'none' : '' }}" style="width: 18%">
                  แบรนด์สินค้า
                </th>
                <th style="width: 24%">ชื่อสินค้า</th>
                <th class="{{ innerWidth < 900 ? 'none' : '' }}" style="width: 8%">รายละเอียด</th>
                <th *ngIf="innerWidth >= 900" style="width: 10%">
                  สัปดาห์ (ขึ้นไป)
                </th>
                <th *ngIf="innerWidth >= 900" style="width: 5%">ปี</th>
                <th *ngIf="innerWidth < 900" style="width: 10%; padding: 10px !important">
                  สัปดาห์ (ขึ้นไป) / ปี
                </th>
                <th *ngIf="innerWidth >= 900" style="width: 10%">
                  จำนวนคงเหลือ
                </th>
                <th *ngIf="innerWidth >= 900" style="width: 10%">ราคา</th>
                <th *ngIf="innerWidth < 900" style="width: 10%; padding: 10px !important">
                  ราคา / (จำนวน)
                </th>
                <th *ngIf="customer_id" style="width: 5%; padding: 10px !important">
                  สั่งซื้อ
                </th>
              </tr>
            </thead>
            <tbody style="font-size: 13px">
              <tr *ngFor="let item of product; let i = index" [ngStyle]="{
                  'background-color':
                    item.isMatch == true ? '#eeecec' : '#ffffff'
                }">
                <!-- <td>{{item.code}}</td>
                <td>{{item.part_number}}</td> -->
                <td>
                  <img src="{{ dataService.host }}{{ item.brand_image }}" alt="" style="
                      width: 100%;
                      height: 48px;
                      object-fit: cover;
                      max-width: 300px;
                    " />
                </td>
                <td style="padding: 3 !important; text-align: left; font-weight: bold;">
                  {{ item.brand_name }} {{ item.product_model_name }}
                  {{ item.width }}/{{ item.series }}R{{ item.rim }}
                  {{ item.load_index }}{{ item.speed_index }} {{ item.OEM_marking }}
                  <label *ngIf="item.country">({{ item.country }})</label>
                  <p style="margin-bottom: 0px; color: #228B22; font-size: 12px !important; font-weight: 500 !important;" *ngFor="let data of item.promotion">
                    {{ data }}
                  </p>
                </td>
                <td>
                  {{ item.description }}
                </td>
                <td *ngIf="innerWidth >= 900">
                  {{ item.week | number : "2.0" }}
                </td>
                <td *ngIf="innerWidth >= 900">
                  {{ item.show_year }}
                </td>
                <td *ngIf="innerWidth < 900" style="padding: 3px !important">
                  {{ item.week | number : "2.0" }}/{{ item.show_year }}
                </td>
                <td *ngIf="innerWidth >= 900" style="font-weight: bold">
                  {{ item.show_qty }}
                </td>
                <td *ngIf="innerWidth >= 900" style="font-weight: bold">
                  {{ item.show_price | number : ".2-2" }}
                </td>
                <td *ngIf="innerWidth < 900" style="font-weight: bold; padding: 3px !important">
                  {{ item.show_price | number : ".2-2" }} ({{ item.show_qty }})
                </td>

                <td *ngIf="customer_id" style="padding: 3px !important">
                  <button style="color: #3d3dc2" *ngIf="dataService.login_type == 'CUSTOMER'"
                    class="btn btn-sm btn-outline-primary" data-toggle="modal" (click)="opensubmodal(contentstack, i)">
                    <i class="fa fa-shopping-cart"></i>
                  </button>

                  <!-- <button class="btn btn-sm btn-outline-warning  " data-toggle="modal"
                    (click)="opensubmodal(contentstack,i)">
                    <i class="fa fa-pencil-alt"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-danger" (click)="onDel(i)">
                    <i class="fa fa-trash"></i>
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div class="table-responsive m-t-40">
          <table class="table stylish-table">
            <thead>
              <tr>
                <th style="width:1%;">#</th>
                <th>รหัสสินค้า</th>

                <th>Part-Number</th>
                <th>แบรนด์สินค้า</th>
                <th>ยี่ห้อ</th>
                <th>รายละเอียด</th>
                <th>หน้ายางกว้าง</th>
                <th>ซีรีย์</th>
                <th>ขอบ</th>
                <th>load_index</th>
                <th>OEM_marking</th>
                <th>สัปดาห์-ปี</th>
                <th>ราคา</th>
                <th>จำนวนคงเหลือ</th>
                <th style="width:15%;">ตั้งค่า</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of data ; let i = index">
              <tr *ngIf="item.brand_name">
                <td>{{i+1}}</td>
                <td>{{item.code}}</td>
                <td>{{item.part_number}}</td>
                <td>
                  {{item.brand_name}}
                </td>
                <td>
                  {{item.product_model_name}}
                </td>
                <td>
                  {{item.description}}
                </td>
                <td>{{item.width}}</td>
                <td>{{item.series}}</td>
                <td>
                  {{item.rim}}
                </td>
                <td>
                  {{item.load_index}}
                </td>
                <td>
                  {{item.OEM_marking}}
                </td>
                <td>
                  {{item.week | number:'2.0' }}-{{item.year}}
                </td>
                <td>
                  {{item.price| number : '.2-2' }}
                </td>
                <td>
                  {{item.qty |number}}
                </td>

                <td>
                  <button class="btn btn-sm btn-outline-warning  " data-toggle="modal"
                    (click)="opensubmodal(contentstack,i)">
                    <i class="fa fa-pencil-alt"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-danger" (click)="onDel(i)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #contentstack let-modal>
  <div *ngFor="let item of insert">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ item.brand_name }} {{ item.product_model_name }} {{ item.width }}/{{
        item.series
        }}R{{ item.rim }}
      </h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <!-- <div class="form-group">
        <table width="100%">
          <tbody>
            <tr>
              <td style="text-align: center" colspan="2">
                <img *ngFor="let item2 of item.product_url" src="{{ dataService.host }}{{ item2 }}" alt=""
                  style="width: 100%; object-fit: cover; max-width: 200px" />
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->

      <div class="form-group">
        <!-- <div style="text-align:center;margin:0; padding:0;"> -->
        <ng-image-slider [images]="imageObject" #nav [manageImageRatio]="true"
          [imageSize]="{width: '30%', height: '30%'}" [imagePopup]="false"></ng-image-slider>
        <!-- </div> -->

      </div>

      <div class="form-group">
        <label style="margin-left: 14px;"> <b>Part Number:</b> </label>
        <label style="margin-left: 28px;"> {{ item.part_number }} </label>
      </div>

      <!-- dynamic display -->

      <div *ngIf="innerWidth >= 900" class="form-group">
        <label style="margin-left: 14px;"> <b>แบรนด์:</b> </label>
        <label style="margin-left: 28px;"> {{ item.brand_name }} </label>
        <label style="margin-left: 28px;"> <b>รุ่น:</b> </label>
        <label style="margin-left: 28px;"> {{ item.product_model_name }} </label>
      </div>

      <div *ngIf="innerWidth < 900" class="form-group">
        <label style="margin-left: 14px;"> <b>แบรนด์:</b> </label>
        <label style="margin-left: 28px;"> {{ item.brand_name }} </label>
      </div>

      <div *ngIf="innerWidth < 900" class="form-group">
        <label style="margin-left: 14px;"> <b>รุ่น:</b> </label>
        <label style="margin-left: 28px;"> {{ item.product_model_name }} </label>
      </div>

      <!-- dynamic display -->

      <div class="form-group">
        <label style="margin-left: 14px;"> <b>ขนาดยาง:</b> </label>
        <label style="margin-left: 28px;"> {{ item.width }}/{{ item.series }}R{{ item.rim }} </label>
      </div>

      <div class="form-group">
        <label style="margin-left: 14px;"> <b>สัปดาห์ (ขึ้นไป)/ปี:</b> </label>
        <label style="margin-left: 28px;"> {{ item.week | number : "2.0" }}/{{ item.show_year }} </label>
      </div>

      <div class="form-group">
        <label style="margin-left: 14px;"> <b>ราคา/เส้น:</b> </label>
        <label style="margin-left: 28px;"> {{ item.show_price | number : ".2-2" }} &nbsp;&nbsp;บาท</label>
      </div>

      <br />

      <div class="form-group">
        <table width="100%">
          <tbody>
            <tr>
              <td style="text-align: right">
                <label> <span>*</span> <b>จำนวน</b> </label>
              </td>
              <td class="col-4 col-sm-4 col-md-3">
                <input type="number" style="float: right;" class="form-control" [(ngModel)]="item.qty" placeholder="0"
                  min="1" oninput="validity.valid||(value='');" id="qty" />
              </td>
              <td style="text-align: left;" class="col-1">
                <label><b>เส้น</b></label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-group" style="float: right;">
        <label style="margin-left: 14px;"> <b>ราคารวม (VAT 7%):</b> </label>
        <label style="margin-left: 28px; margin-right: 12px;"> <b>{{ item.show_price * item.qty | number : ".2-2" }}
            &nbsp;&nbsp;บาท</b> </label>
      </div>

      <br />

      <div class="form-group">
        <label style="color: red;"> *** ราคาดังกล่าวยังไม่รวมค่าบริการพิเศษอื่นๆ *** </label>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="closesubmodal(contentstack)">
        สั่งซื้อ
      </button>
    </div>
  </div>
</ng-template>