import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import {
  CommonModule,
  LocationStrategy,
  PathLocationStrategy,
  HashLocationStrategy
} from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule, HttpClient } from '@angular/common/http'
import { Routes, RouterModule } from '@angular/router'
import { HttpModule } from '@angular/http'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { FullComponent } from './layouts/full/full.component'

import { NavigationComponent } from './shared/header-navigation/navigation.component'
import { SidebarComponent } from './shared/sidebar/sidebar.component'
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component'

import { Approutes } from './app-routing.module'
import { AppComponent } from './app.component'
import { SpinnerComponent } from './shared/spinner.component'

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar'
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'

import { DataService } from '../app/app.service'
// Import routing module
import { AppRoutingModule } from '../app/app-routing.module'
import { LoginComponent } from './login/login.component'
import { ProductComponent } from './product/product.component'
import { OrderComponent } from './bill/view1/view1.component'
import { CartsComponent } from './bill/view2/view2.component'

import { DataTablesModule } from 'angular-datatables';

import { SpinnersAngularModule } from 'spinners-angular';

// import { NgImageSliderModule } from 'ng-image-slider';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
}

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    NavigationComponent,
    SidebarComponent,
    BreadcrumbComponent,
    LoginComponent,
    ProductComponent,
    OrderComponent,
    CartsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    PerfectScrollbarModule,
    NgbModule,
    RouterModule.forRoot(Approutes, { useHash: false }),
    HttpModule,
    DataTablesModule,
    SpinnersAngularModule,
    
    // AppRoutingModule
  ],
  providers: [
    {
      provide: LocationStrategy,
      //   useClass: PathLocationStrategy
      useClass: HashLocationStrategy
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
