import { Http, Response } from '@angular/http'
import { Injectable } from '@angular/core'
import { Observable, Subject } from 'rxjs'
import 'rxjs'
import { delay } from 'q'
import { isUndefined, isArray, isObject } from 'util'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'

declare var require
var moment = require('moment-timezone')

@Injectable()
export class DataService {
  host = 'https://jcloudprod.com/db_car/'
  // host = 'http://localhost:8888/db_car/'

  data: any[] = []
  setting: any[] = []
  // date = new Date().toJSON().slice(0,10);ß
  // date = '2020-01-15'
  date = moment()
    .tz('Asia/Bangkok')
    .format()
    .slice(0, 10)
  type_product: any[] = [
    { id: '1', name: 'ยาง' },
    { id: '2', name: 'ล้อแม็ก' },
    { id: '3', name: 'ช่วงล่าง' },
    { id: '4', name: 'ท่อไอเสีย' },
    { id: '5', name: 'Aerodynamic parts' },
    { id: '6', name: 'สินค้าอื่นๆ' }
  ]
  private loggedInStatus = JSON.parse(
    localStorage.getItem('loggedIn2') || 'false'
  )
  login: any[] = []
  count: any = 0
  show: any = 0
  login_type = 'CUSTOMER'
  constructor(private http: Http, private router: Router
  ) {
  }

  /******************************* Get Database ****************************************** */
  get_data(data) {
    return new Promise<void>(resolve => {
      this.http
        .post(
          this.host + 'select_db.php',
          JSON.stringify({
            data: data
          })
        )
        .subscribe(
          res => {
            this.data = res.json()
            setTimeout(resolve, 100)
          },
          error => {
            console.log(JSON.stringify(error.json()))
          }
        )
    })
  }

  async get_count() {
    return new Promise<void>(resolve => {
      this.http
        .post(
          this.host + 'select_db.php',
          JSON.stringify({
            data: { do: 'get_count_purchase_order' }
          })
        )
        .subscribe(
          res => {
            var data = res.json()
            setTimeout(resolve, 100)
            if (data != null)
              data[0].count > 0 ? this.count = data[0].count : this.count = 0
          },
          error => {
            console.log(JSON.stringify(error.json()))
          }
        )
    })
  }

  /******************************* Get Database ****************************************** */

  set_data(data) {
    let user = this.login[0].username
    data.created_by = user
    data.updated_by = user

    const formData = new FormData()
    if (isObject(data.image)) {
      formData.append('file', data.image)
    }

    if (isArray(data.url_image)) {
      formData.append('file0', data.url_image[0])
      formData.append('file1', data.url_image[1])
      formData.append('file2', data.url_image[2])
      formData.append('file3', data.url_image[3])
      //formData.append('file4', data.url_image[4])
    }
    formData.append('data', JSON.stringify(data))

    return new Promise<void>(resolve => {
      this.http.post(this.host + 'insert_db.php', formData).subscribe(
        res => {
          setTimeout(resolve, 100)
          if (res.json() == 'Save Ok') {
            alert('ทำรายการสำเร็จ')
          } else {
            alert('ทำรายการไม่สำเร็จ')
          }
        },
        error => {
          console.log(JSON.stringify(error.json()))
        }
      )
    })
  }

  set_stock(data) {
    let user = this.login[0].username
    data.created_by = user
    data.updated_by = user

    const formData = new FormData()
    if (isObject(data.image)) {
      formData.append('file', data.image)
    }

    if (isArray(data.url_image)) {
      formData.append('file0', data.url_image[0])
      formData.append('file1', data.url_image[1])
      formData.append('file2', data.url_image[2])
      formData.append('file3', data.url_image[3])
      //formData.append('file4', data.url_image[4])
    }
    formData.append('data', JSON.stringify(data))

    return new Promise<void>(resolve => {
      this.http.post(this.host + 'insert_db.php', formData).subscribe(
        res => {
          setTimeout(resolve, 100)
          // if (res.json() == 'Save Ok') {
          //   alert('ทำรายการสำเร็จ')
          // } else {
          //   alert('ทำรายการไม่สำเร็จ')
          // }
        },
        error => {
          console.log(JSON.stringify(error.json()))
        }
      )
    })
  }

  /******************************* Set Database ****************************************** */

  /******************************* Export Database ****************************************** */
  exportdata(data, path) {
    alert('Download Report')
    this.http
      .post(
        this.host + '/PHPExcel/' + path,
        JSON.stringify({
          data: data //this
        })
      )
      .subscribe(
        async data => {
          let url = this.host + '/PHPExcel/' + data.json()
          window.open(url, '_blank')
        },
        error => {
          console.log(JSON.stringify(error.json()))
        }
      )
  }


  exportPDF(data, path) {
    window.open(this.host + 'report/' + path + '?id=' + data, '_blank')
    // return new Promise<void>(resolve => {
    //   alert('Download Report')
    //   this.http
    //     .post(
    //       this.host + '/report/' + path,
    //       JSON.stringify({
    //         data: data //this
    //       })
    //     )
    //     .subscribe(
    //       async data => {
    //         // console.log(data.json())
    //         // let url = this.host + '/report/' + data.json()
    //         // window.open(url, '_blank')
    //       },
    //       error => {
    //         console.log(JSON.stringify(error.json()))
    //       }
    //     )
    // })
  }
  /******************************* Export Database ****************************************** */

  /********************Login************************** */
  get isLoggedIn() {
    return JSON.parse(
      localStorage.getItem('loggedIn2') || this.loggedInStatus.toString()
    )
  }

  setLogIn(value: any) {
    localStorage.setItem('loggedIn2', 'true')
    localStorage.setItem('loguser2', JSON.stringify(value))
    localStorage.setItem('loginType', this.login_type)
  }

  async isLogIn() {
    this.login = JSON.parse(localStorage.getItem('loguser2'))
    this.login_type = localStorage.getItem('loginType')

    if (this.login_type == 'CUSTOMER') {
      await this.get_count()
      await this.get_noti_cart()
    }
  }

  logout() {
    localStorage.removeItem('loguser2')
    // localStorage.removeItem('loggedIn')
    localStorage.setItem('loggedIn2', 'false');
    this.login = []
  }

  async getLogin(username, password, login_type) {
    // await this.get_data({ do: 'get_login', username: username, password: password, id: id })
    this.login_type = login_type;
    let type = 'get_login_customer';
    if (login_type == 'CUSTOMER') type = 'get_login_customer'
    else type = 'get_login'

    await this.get_data({ do: type, username: username, password: password })
    this.login = this.data

    if (this.login[0] != 0) {
      this.setLogIn(this.login)
    }
  }


  async get_noti_cart() {
    // await this.isLogIn()
    let id = this.login[0].customer_id
    await this.get_data({ do: 'get_count_purchase_carts', id: id })
    // this.data = this.data
    if (this.data[0] == 0) {
      this.show = 0
      this.data = []
      return
    }
    this.show = await this.data[0].count
  }

  /********************Login************************** */

  async delay(milliseconds: number) {
    return new Promise<void>(resolve => {
      setTimeout(resolve, milliseconds)
    })
  }
}
