<app-navigation> </app-navigation>


<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-lg-3">
            <div class="ml-auto">
              <input type="date" class="form-control" [(ngModel)]="s_date" placeholder="" id="s_date"
                (change)="get_data()" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="ml-auto">
              <input type="date" class="form-control" [(ngModel)]="e_date" placeholder="" id="e_date"
                (change)="get_data()" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="ml-auto">
              <select class="custom-select" [(ngModel)]="status" (change)="get_data()">
                <option [ngValue]="null"> *** เลือกสถานะ *** </option>
                <option value="WAITING"> WAITING </option>
                <option value="APPROVE"> APPROVE </option>
                <option value="REJECT"> REJECT </option>


                <!-- <option *ngFor="let get of category" [ngValue]="get.id">
                  {{ get.name }}
                </option> -->
              </select>
            </div>
          </div>

          <div class="col-lg-3">
            <div class="d-md-flex align-items-left">
              <!-- <div class="ml-auto">
                <button *ngIf="p_add" class="btn btn-sm btn-outline-primary" (click)="opensubmodal(contentstack,null)">
                  เพิ่ม +
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <div class="d-md-flex align-items-center">
          <h4 class="card-title mb-0">สีแบรนด์สินค้า</h4>
          <div class="ml-auto">
            <button
              class="btn btn-sm btn-outline-primary"
              (click)="opensubmodal(contentstack,null)"
            >
              เพิ่ม +
            </button>
          </div>
        </div> -->

        <div class="table-responsive m-t-40">
          <table datatable name="myTable" id="myTable" [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
            class="row-border hover">
            <thead>
              <tr>
                <th style="width:1%;">#</th>
                <th style="width:10%;">เลขใบสั่งซื้อ</th>
                <th style="width:20%;">ชื่อลูกค้า</th>
                <th>จำนวน</th>
                <th>ราคาเต็ม</th>
                <th>ส่วนลด</th>
                <th>ราคาสุทธิ</th>
                <th>สถานะ</th>
                <th>สถานะการส่งสินค้า</th>
                <th>วันที่เพิ่มข้อมูล</th>
                <th>แก้ไขข้อมูล</th>
                <!-- <th style="width:15%;">แก้ไขสถานะ</th> -->
                <th style="width:10%;">ตั้งค่า</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data ; let i = index">
                <td>{{i+1}}</td>
                <td>{{item.code}}</td>
                <td>{{item.customer_name}}</td>
                <td>
                  {{item.total_qty | number }}
                </td>

                <td>
                  {{item.total_price | number : '.2-2'}}
                </td>
                <td>
                  {{item.discount | number : '.2-2'}}
                </td>

                <td>
                  {{item.net_price | number : '.2-2'}}
                </td>
                <td>
                  {{item.status}}
                </td>
                <td>
                  {{item.check_status == 'Y' ?'YES':'NO'}}
                </td>
                <td>{{item.created_date}}</td>
                <td>{{item.updated_date}}</td>
                <!-- <td>
                  <button class="btn btn-sm btn-outline-success" (click)="onEditStatus(i,'APPROVE')"
                    *ngIf="item.status == 'WAITING'">
                    <i class="fa fa-check"></i>
                  </button>

                  <button class="btn btn-sm btn-outline-danger" (click)="onEditStatus(i,'REJECT')"
                    *ngIf="item.status == 'WAITING'">
                    <i class="fa fa-times"></i>
                  </button>
                </td> -->
                <td>
                  <button class="btn btn-sm btn-outline-primary" (click)="print(i)">
                    <i class="fa fa-qrcode"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-success  " (click)="open_web(item.id,item.code)">
                    <i class="fa fa-arrow-right"></i>
                  </button>

                  <button *ngIf="p_delete" class="btn btn-sm btn-outline-danger" (click)="onDel(i)">
                    <i class="fa fa-trash"></i>
                  </button>

                  <button class="btn btn-sm btn-outline-warning" (click)="onReport(i)">
                    <i class="fa fa-print"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>


        <!-- <div class="table-responsive m-t-40">
          <table class="table stylish-table">
            <thead>
              <tr>
                <th style="width:1%;">#</th>
                <th style="width:10%;">เลขใบสั่งซื้อ</th>
                <th style="width:20%;">ชื่อลูกค้า</th>
                <th>จำนวน</th>
                <th>ราคาเต็ม</th>
                <th>ส่วนลด</th>
                <th>ราคาสุทธิ</th>
                <th>สถานะ</th>
                <th>วันที่เพิ่มข้อมูล</th>
                <th>แก้ไขข้อมูล</th>
                <th style="width:15%;">แก้ไขสถานะ</th>
                <th style="width:30%;">ตั้งค่า</th>
              </tr>
            </thead>
            <tbody *ngFor="let item of data ; let i = index">
              <tr *ngIf="item.code">
                <td>{{i+1}}</td>
                <td>{{item.code}}</td>
                <td>{{item.customer_name}}</td>
                <td>
                  {{item.total_qty | number }}
                </td>
                <td>
                  {{item.total_price | number : '.2-2'}}
                </td>
                <td>
                  {{item.discount | number : '.2-2'}}
                </td>

                <td>
                  {{item.net_price | number : '.2-2'}}
                </td>
                <td>
                  {{item.status}}
                </td>
                <td>{{item.created_date}}</td>
                <td>{{item.updated_date}}</td>
                <td>
                  <button class="btn btn-sm btn-outline-success" (click)="onEditStatus(i,'APPROVE')"
                    *ngIf="item.status == 'WAITING'">
                    <i class="fa fa-check"></i>
                  </button>

                  <button class="btn btn-sm btn-outline-danger" (click)="onEditStatus(i,'REJECT')"
                    *ngIf="item.status == 'WAITING'">
                    <i class="fa fa-times"></i>
                  </button>
                </td>
                <td>
                  <button class="btn btn-sm btn-outline-primary" (click)="print(i)">
                    <i class="fa fa-qrcode"></i>
                  </button>
                  <button class="btn btn-sm btn-outline-success  "
                    (click)="open_web(item.code,item.group_id,item.id,item.customer_id,item.status)">
                    <i class="fa fa-arrow-right"></i>
                  </button>

                  <button class="btn btn-sm btn-outline-danger" (click)="onDel(i)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
      </div>
    </div>
  </div>
</div>

<ng-template #contentstack let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">กรอกข้อมูล</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <!-- <label>ยี่ห้อรถ</label> -->
      <table width="100%" *ngFor="let item of insert">
        <tbody>
          <tr>
            <td>
              <label><span>*</span> เลขใบสั่งซื้อ </label>
              <input type="text" class="form-control" [(ngModel)]="item.code" placeholder="" id="code" disabled />
            </td>
          </tr>
          <tr>
            <td>
              <label><span>*</span> เลือกลูกค้า</label>

              <div class="ml-auto">
                <!-- <select class="custom-select" [(ngModel)]="item.customer_id" id='customer_id'>
                  <option [ngValue]="null">
                    *** เลือกเลือกลูกค้า ***
                  </option>
                  <option *ngFor="let get of customer" [ngValue]="get.id">
                    {{ get.name }}
                  </option>
                </select> -->

                <input type="text" list="codes" id='customer_id' class="form-control" [(ngModel)]="codeValue"
                  placeholder="*** เลือกเลือกลูกค้า ***
                " (change)="saveCode($event)">
                <datalist id="codes">
                  <option *ngFor="let c of customer" [value]="c.name"></option>
                </datalist>

              </div>
            </td>
          </tr>
          <!-- <tr>
            <td>
              <label><span>*</span> ชื่อกลุ่ม </label>
              <input type="text" class="form-control" [(ngModel)]="item.name" placeholder="" id="name"
                (keyup)="check_name(item.name)" />
            </td>
          </tr>
          <tr>
            <td>
              <label>รายละเอียด</label>
              <textarea class="form-control" [(ngModel)]="item.description" placeholder="" id="description"></textarea>
            </td>
          </tr> -->



        </tbody>
      </table>
    </div>
  </div>
  <!-- (click)="modal.close('Save click')" -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="closesubmodal(contentstack)">
      Save
    </button>
  </div>
</ng-template>

<div class="row" hidden>
  <div id="contnet">
    <div class="container">
      <div *ngFor="let item of this.qrcode ; let i = index">
        <table class="print" style=" 
            height : 45mm ; width: 75mm;
            font-size: 100%;
            border: 1px solid black;
            margin-top: 2mm;

          ">
          <!-- <table style="height : 150mm ; width: 180mm;text-align: left; font-size: 100%; border: 1px solid black; "> -->
          <tr>
            <td>
              <p> <b>เลขใบสั่งซื้อ :</b> {{item.code}}</p>
            </td>
          </tr>
          <tr>
            <td align="center">
              <img src="https://chart.googleapis.com/chart?chs=120x120&cht=qr&chl={{item.code}} &choe=UTF-8"
                title="Link to my Website" />
            </td>
          </tr>

          <!-- <tr>
            <td>
              <p> <b>Part No :</b> {{item.part_number}}</p>
            </td>
          </tr>
          <tr>

            <td>
              <p> <b> ประเภท :</b> {{item.category_name}}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p> <b>แบรนด์ :</b> {{item.brand_name}}</p>
            </td>
          </tr>
          <tr>
            <td>
              <p> <b>ชื่อสินค้า :</b> {{item.product_model_name}}</p>
            </td>
          </tr> -->
        </table>
      </div>
    </div>
  </div>
</div>