<app-navigation> </app-navigation>

<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="row" *ngFor="let item of insert">
          <div class="col-lg-3">
            <h2><b>ข้อมูลออเดอร์ </b></h2>
          </div>
        </div>

        <br>

        <div class="row">
          <div class="col-lg-3">
            <h4>
              <b>สรุปออเดอร์ : </b>
              <b *ngIf="case == 2"> {{name}}</b>
            </h4>
          </div>
        </div>
        <br>

        <div class="row">
          <div class="col-lg-6">
            <h5><b>ข้อมูลในใบเสร็จรับเงิน</b></h5>
          </div>

          <div class="col-lg-6">
            <!-- <div style="float: 'right'">
              <h5><b>ข้อมูลการจัดส่งสินค้า</b></h5>
            </div> -->
          </div>
        </div>

        <div class="row" *ngFor="let cus of customer">
          <div class="col-lg-6">
            <Table>
              <tr style="text-align: left;">
                <td>
                  {{cus.name}}
                </td>
              </tr>
              <tr style="text-align: left;">
                <td>
                  {{cus.address}}
                </td>
              </tr>
              <tr style="text-align: left;">
                <td>
                  โทรศัพท์ : {{cus.tel}}
                </td>
              </tr>

            </Table>
          </div>

          <!-- <div class="col-lg-6" >
            <Table style="float: 'right'">
              <tr>
                <td>
                </td>
              </tr>
            </Table>
          </div> -->
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table stylish-table-hover">
            <thead style="background-color:lightsteelblue">
              <tr>
                <th style="width: 1%">#</th>
                <th style="width: 30%">ชื่อสินค้า</th>
                <th style="width: 15%">สัปดาห์(ขึ้นไป)</th>
                <th style="width: 5%">ปี</th>
                <th style="width: 5%">จำนวน</th>
                <th style="width: 5%">ราคา/ชิ้น</th>
                <th style="width: 10%">ราคาเต็ม</th>
                <th style="width: 15%">ส่วนลด</th>
                <th style="width: 10%">ราคาสุทธิ</th>
                <!-- <th style="width: 10%">วันที่สั่งซื้อ</th> -->
                <th style="width: 1%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data; let i = index">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ item.brand_name }} {{ item.product_model_name }}
                  {{ item.width }}/{{ item.series }}R{{ item.rim }}
                  {{ item.load_index }}{{ item.speed_index }} {{ item.OEM_marking }}
                </td>
                <td>
                  {{ item.week | number : "2.0" }}
                </td>
                <td>
                  {{ item.show_year }}
                </td>
                <td>{{ item.qty | number }}</td>
                <td>{{ item.priceperunit | number : ".2-2" }}</td>
                <td>{{ item.price | number : ".2-2" }}</td>
                <td>{{ item.discount_actually_baht | number : ".2-2" }}</td>
                <td>{{ item.net_price | number : ".2-2" }}</td>
                <!-- <td>{{ item.created_date }}</td> -->
                <td>
                  <button class="btn btn-sm btn-outline-danger" (click)="onDel(i)">
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
              <tr>
                <td colspan="7"></td>
                <td colspan="2" style="text-align: right;">
                  <p>ราคาสินค้า</p>
                  <p>ส่วนลด</p>
                  <!-- <p>VAT</p> -->

                </td>
                <td style="text-align: right;">
                  <p>{{sum_total.total_price | number : ".2-2"}}</p>
                  <p>{{sum_total.total_discount | number : ".2-2"}}</p>
                  <!-- <p>VAT</p> -->
                </td>
              </tr>
              <tr>
                <td colspan="7"></td>
                <td colspan="2" style="text-align: right;">
                  <h4 style="color: darkblue;">
                    <b>
                      รวมทั้งหมด
                    </b>
                  </h4>
                </td>
                <td style="text-align: right;">
                  <h4 style="color: darkblue;">
                    <b>
                      {{sum_total.total_net | number : ".2-2"}}
                    </b>
                  </h4>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <hr>

        <div class="row" *ngIf="case == 1">
          <!-- <div class="col-lg-6">

          </div> -->

          <div class="col-lg-12">
            <Table widht="100%" align="right">
              <tr>
                <td>
                  <h4 style="color: darkblue;"><b>ยืนยันการสั่งซื้อของคุณ</b></h4>
                </td>
              </tr>
              <tr>
                <td style="float: right;">
                  <button class="btn btn-sm btn-outline-primary" (click)="onSave()">
                    ยืนยันออร์เดอร์
                  </button>
                </td>
              </tr>
            </Table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #contentstack let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">กรอกข้อมูล</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <!-- <label>ยี่ห้อรถ</label> -->
      <table width="100%" *ngFor="let item of insert">
        <tbody>
          <tr>
            <td>
              <label><span>*</span> ประเภทสินค้า</label>

              <div class="ml-auto">
                <select class="custom-select" [(ngModel)]="item.category_id" id="category_id"
                  (change)="get_sub_category(item.category_id)">
                  <option [ngValue]="null">*** เลือกประเภทสินค้า ***</option>
                  <option *ngFor="let get of category" [ngValue]="get.id">
                    {{ get.name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr *ngIf="sub_category.length > 0">
            <td>
              <label><span>*</span> เลือกประเภทสินค้าย่อย</label>

              <div class="ml-auto">
                <select class="custom-select" [(ngModel)]="item.sub_category_id" id="sub_category_id"
                  (change)="get_brand(item.sub_category_id)">
                  <option [ngValue]="null">
                    *** เลือกประเภทสินค้าย่อย ***
                  </option>
                  <option *ngFor="let get of sub_category" [ngValue]="get.id">
                    {{ get.name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label><span>*</span> แบรนด์สินค้า</label>

              <div class="ml-auto">
                <select class="custom-select" [(ngModel)]="item.brand_id" (change)="valueSelected(item.brand_id)"
                  id="brand_id">
                  <option [ngValue]="null">*** เลือกแบรนด์สินค้า ***</option>
                  <option *ngFor="let get of brand" [ngValue]="get.id">
                    {{ get.name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label><span>*</span> ชื่อรุ่นสินค้า</label>

              <div class="ml-auto">
                <select class="custom-select" [(ngModel)]="item.product_model_id" id="product_model_id"
                  (change)="get_product(item.product_model_id)">
                  <option [ngValue]="null">*** เลือกรุ่นสินค้า ***</option>
                  <option *ngFor="let get of product_model" [ngValue]="get.id">
                    <!-- {{ get.name }} -->
                    {{ get.name }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label><span>*</span> ชื่อสินค้า</label>
              <div class="ml-auto">
                <select class="custom-select" [(ngModel)]="item.product_id" id="product_id"
                  (change)="check_discount(item.product_id)">
                  <option [ngValue]="null">*** เลือกสินค้า ***</option>
                  <option *ngFor="let get of product" [ngValue]="get.id">
                    <!-- {{ get.name }} -->
                    {{ get.code }} | {{ get.part_number }} | คงเหลือ
                    {{ get.qty }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label><span>*</span> จำนวนสินค้า</label>
              <input type="number" class="form-control" [(ngModel)]="item.qty" placeholder="" id="qty" min="0"
                oninput="validity.valid||(value='');" />
            </td>
          </tr>

          <!-- <tr *ngIf="item.category_id == 1">
            <td>
              <label><span>*</span> ปีที่ผลิต </label>

              <div class="ml-auto">
                <select class="custom-select" [(ngModel)]="item.year" id='year'>
                  <option [ngValue]="null"> *** เลือกปีที่ผลิต *** </option>
                  <option *ngFor="let get of year" [ngValue]="get.year">
                    {{ get.year }}
                  </option>
                </select>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <label><span>*</span> ส่วนลด</label>
              <br>

              <input type="radio" [(ngModel)]="item.discount_type" placeholder="" id="discount_type" value="N" />
              ส่วนลดเป็นจำนวนเต็ม (บาท)
              <input type="radio" [(ngModel)]="item.discount_type" placeholder="" id="discount_type" value="P" />
              ส่วนลดเป็นเปอร์เซ็นท์ (%)
              <br>

              <input type="number" class="form-control" [(ngModel)]="item.discount" placeholder="" id="discount" />
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
  <!-- (click)="modal.close('Save click')" -->
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="closesubmodal(contentstack)">
      บันทึก
    </button>
  </div>
</ng-template>