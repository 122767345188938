import { DataService } from '../app.service'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'
import { Http, Response } from '@angular/http'
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
  username: any = null
  password: any = null
  login_type: any = 'CUSTOMER'
  status_login: number

  insert: any[] = [{ id: 0 }]
  constructor(
    private http: Http,
    private auth: DataService,
    private router: Router
  ) { }

  async ngOnInit() {
    if (this.auth.isLoggedIn) {
      this.router.navigate(['product'])
      return
    } else {
      localStorage.setItem('loggedIn2', 'false')
      this.router.navigate([''])
    }
  }

  async onSubmit() {
    // await this.auth.getLogin("admin", "1234", null);
    // this.router.navigate(['dashboard'])
    await this.auth.getLogin(this.username, this.password, this.login_type)

    if (this.auth.login[0] != 0) {
      this.status_login = 0
      this.router.navigate(['product'])
      return
    } else {
      this.status_login = 1
      localStorage.removeItem('loggedIn2')
      return
    }
  }
}
