import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core'
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../../app.service'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-view1',
  templateUrl: './view1.component.html',
  styleUrls: ['../css.component.scss']
})
export class OrderComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();

  closeResult: string
  files: any[]
  data: any = []
  category_type = null
  insert: any[] = [
    {
      do: null,
      customer_id: null,
      name: null,
      type: null,
      description: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null
    }
  ]
  img: any = null
  type_product: any[] = []
  category: any[]
  brand: any[]
  select_brand: any
  select_data: any
  select_model: any[]
  sub_category: any[] = []
  select_category: any[] = []
  select_product: any[]
  product: any
  customer: any[]
  setting_code: any[]
  category_id = '110'
  qrcode: any[] = [];
  date: any
  s_date: any
  e_date: any
  status = null
  purchase_carts: any[]
  select_customer: any[];
  p_add: any;
  p_update: any;
  p_delete: any;
  customer_id: any;


  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
    private router: Router

  ) { }

  async ngOnInit() {
    await this.dataService.isLogIn()
    this.customer_id = this.dataService.login[0].customer_id

    //permission
    this.p_add = this.dataService.login[0].order_add;
    this.p_update = this.dataService.login[0].order_update;
    this.p_delete = this.dataService.login[0].order_delete;
    // this.p_print = this.dataService.login[0].order_print;

    this.s_date = this.dataService.date;
    this.e_date = this.dataService.date;
    await this.get_category()
    await this.get_setting_code(this.category_id)
    await this.get_customer()
    await this.get_data()

    this.dtOptions = {
      pagingType: 'full_numbers',
    };
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  opensubmodal(contentsubmodal, i) {
    this.modalService.open(contentsubmodal, { size: 'md' })
    if (i == null) {
      this.insert[0] = {
        customer_id: null,
        category_id: this.category_id,
        code: this.setting_code[0].code + '-' + this.setting_code[0].number
      }
      this.insert[0].do = 'add_purchase_order'
    } else {
      this.insert[0] = this.data[i]
      this.insert[0].do = 'edit_purchase_order'
    }
  }

  async closesubmodal(contentsubmodal) {
    let input = this.insert[0]
    let set = ['customer_id']

    for (let i = 0; i < set.length; i++) {
      if (input[set[i]] == null) {
        document.getElementById(set[i]).focus()
        alert('กรุณาระบุข้อมูลให้ครบ')
        return
      }
    }

    this.modalService.dismissAll()
    this.onSave()
  }

  async check_name(filterVal) {
    var chk = []
    await this.dataService.get_data({
      do: 'get_purchase_order'
    })
    chk = this.dataService.data

    var index = chk.findIndex(function (item, i) {
      return item.name.toLowerCase() == filterVal.toLowerCase()
    })
    if (index > -1) {
      alert('ชื่อนี้เคยใช้แล้ว')
    }
  }

  save_img(fileInput) {
    this.img = fileInput.target.files[0]
  }

  public saveCode(e): void {
    let find = this.customer.find(x => x?.name === e.target.value);
    this.insert[0].customer_id = find?.id
  }

  async get_setting_code(e) {
    await this.dataService.get_data({ do: 'get_setting_code', category_id: e })
    this.setting_code = this.dataService.data
  }


  async get_category() {
    await this.dataService.get_data({ do: 'get_category' })
    this.select_category = this.dataService.data
    this.category = this.select_category.filter(
      item => item.parent_id === null
    )

  }
  async get_customer() {
    await this.dataService.get_data({ do: 'get_customer' })
    this.customer = this.dataService.data
  }

  async get_data() {
    await this.dataService.get_data({ do: 'get_purchase_order_customer', s_date: this.s_date, e_date: this.e_date, id: this.customer_id })
    this.select_data = this.dataService.data
    this.data = this.select_data
    // this.status != null ? this.data = this.select_data.filter(
    //   item => item.status === this.status
    // ) : this.data = this.select_data

    if (this.data[0] == 0) this.data = []

    console.log('this.data')
    console.log(this.data)
  }

  async get_product(e) {
    var tb = this.category.filter(
      item => item.id === e
    )
    var table_name = tb[0].table_name.replace("tbl", "get");
    await this.dataService.get_data({ do: table_name })
    this.product = this.dataService.data
  }

  async get_purchase_carts(e) {
    await this.dataService.get_data({ do: 'get_purchase_carts', id: e })
    this.purchase_carts = this.dataService.data
  }

  async onEditStatus(e, s) {
    if (confirm('ต้องการ ' + s + ' หรือไม่?')) {
      this.insert[0] = this.data[e]
      this.insert[0].do = 'edit_purchase_order'
      this.insert[0].status = s

      if (s == 'APPROVE') {
        await this.get_purchase_carts(this.insert[0].code)

        for (let i = 0; i < this.purchase_carts.length; i++) {
          await this.get_product(this.purchase_carts[i].category_id);
          let pro = this.product.filter(
            item => item.code === this.purchase_carts[i].product_code
          )

          if (parseInt(pro[0].qty) < parseInt(this.purchase_carts[i].qty)) {
            alert("จำนวนสินค้าไม่เพียงพอ : " + pro[0].qty + " ชิ้น")
            return
          }
        }
      }


      await this.dataService.set_data(this.insert[0])
      this.get_data()
    }
  }

  async onSave() {
    await this.dataService.set_data(this.insert[0])
    this.get_data()
    window.location.reload();

  }
  async onDel(e) {
    if (confirm('ต้องการจะลบหรือไม่?')) {
      this.insert[0] = this.data[e]
      this.insert[0].do = 'del_purchase_order'
      await this.dataService.set_data(this.insert[0])
      this.get_data()
      window.location.reload();

    }
  }

  async onReport(e) {
    this.insert[0] = this.data[e]
    await this.dataService.exportPDF(this.insert[0].code, 'invoice.php');
  }

  async print(i) {
    this.qrcode[0] = this.data[i]
    await this.dataService.delay(1000)
    var sOption = "toolbar=no,location=no,directories=yes,menubar=no,";
    sOption += "scrollbars=yes,width=775,height=600,left=10,top=25 margin-top= 2cm";

    // var printW = window.open("");

    var printW = window.open("", "", sOption);

    var html = $('#contnet').html();

    printW.document.write(html);
    // printW.document.write('<link href="../css.component.scss" media="print,screen" rel="stylesheet" type="text/scss" />'); //css path
    printW.document.close();
    printW.focus();
    printW.print();
    printW.close();
  }

  open_web(id, name) {
    this.router.navigate(['/carts'], {
      // queryParams: { id: e, gp: gp, po: po, ct: ct, st: st }
      queryParams: { id: id, name: name, case: 2 }
    })
  }
}
