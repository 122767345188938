import { Component, EventEmitter, Output } from '@angular/core';
import { DataService } from '../../app.service'
import { Router, NavigationEnd, NavigationStart } from '@angular/router'

declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./css.css']
})
export class NavigationComponent {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  name = null
  customer_id: any;
  data: any;
  show = 0
  login_type: string;
  constructor(private auth: DataService,
    private router: Router

  ) {

  }


  async ngOnInit() {
    await this.auth.isLogIn()
    this.name = this.auth.login[0].username

    // this.show = this.auth.show
    this.login_type = this.auth.login_type;
    // this.customer_id = this.auth.login[0].customer_id
    // await this.get_data()
  }

  async logout() {
    await this.auth.logout()
    this.router.navigate([''])
  }

  open_order() {
    this.router.navigate(['/order'], {
      // queryParams: { id: e, gp: gp, po: po, ct: ct, st: st }
    })
  }

  open_cart() {
    this.router.navigate(['/carts'], {
      queryParams: { case: 1 }
    })
  }

  open_product() {
    this.router.navigate(['/product'], {

    })
  }
}
