<div class="row">
  <div class="col-sm-12">
    <div class="card">
      <div class="card-header" style="background-color: white;">

        <div class="row">
          <div class="col-sm-6">
            <img src="assets/images/img/top-form.png" alt="homepage" class="dark-logo" width="300" />
          </div>

          <div class="col-sm-6">
            <ul class="navbar-nav float-right ">
              <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
                  href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <img src="assets/images/img/logout.jpg" alt="user" class="rounded-circle" width="40" />
                </a>

                <div class="dropdown-menu user-dd" ngbDropdownMenu>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item">
                    ผู้ใช้งาน : {{name}}
                  </a>
                  <!-- <a class="dropdown-item" >
                        <i class="mdi mdi-cart m-r-5 m-l-5"></i>
                      </a>
                      <a class="dropdown-item" >
                        History
                      </a> -->
                  <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                    <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                </div>
              </li>
            </ul>

            <div class="navbar-nav float-right " *ngIf="login_type == 'CUSTOMER'">
              <button class="btn btn-sm btn-outline-success"
                style="margin-top: 10px; margin-right: 5px; border-radius: 30px;" (click)="open_order()">
                <i class="mdi mdi-clock m-r-5 m-l-5" style="font-size: large; "></i>
              </button>
            </div>

            <!-- <div class="navbar-nav float-right "> -->
            <div class="notification" *ngIf="login_type == 'CUSTOMER'">

              <div class="navbar-nav float-right ">

                <button class="btn btn-sm btn-outline-danger"
                  style="margin-top: 10px; margin-right: 5px; border-radius: 30px;" (click)="open_cart()">
                  <i class="mdi mdi-cart m-r-5 m-l-5" style="font-size: large; "></i>

                  <span class="badge" *ngIf="auth.show>0">{{auth.show}}</span>

                </button>
              </div>

            </div>

            <div class="navbar-nav float-right">
              <button class="btn btn-sm btn-outline-info"
                style="margin-top: 10px; margin-right: 5px; border-radius: 30px;" (click)="open_product()">
                <i class="mdi mdi-home m-r-5 m-l-5" style="font-size: large; "></i>
              </button>
            </div>

          </div>
        </div>




      </div>
    </div>
  </div>
</div>

<!-- <div class="row">
  <div class="col-sm-12"> 
    <div class="card" >
      <div class="card-body">
        <ul class="navbar-nav float-left ml-auto ">

          <li class="nav-item dropdown">
            <div class="navbar-nav float-left ml-auto">
                  <img src="assets/images/img/top-form.png" alt="homepage" class="dark-logo" width="20%" />
            </div>
            </li>
        
        </ul>
        
        <ul class="navbar-nav float-right ">
         
          <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
            <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
              href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img src="assets/images/img/logout.jpg" alt="user" class="rounded-circle" width="40" />
            </a>
        
            <div class="dropdown-menu user-dd" ngbDropdownMenu>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item">
                ผู้ใช้งาน : {{name}}
              </a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="logout()">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            </div>
          </li>
        
        </ul>
      </div>
    </div>
  </div>
</div> -->