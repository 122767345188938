import { Component, ViewEncapsulation, OnInit, ViewChild } from '@angular/core'
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal
} from '@ng-bootstrap/ng-bootstrap'
import { DataService } from '../../app.service'
import { Router, ActivatedRoute, Params } from '@angular/router'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-view2',
  templateUrl: './view2.component.html',
  styleUrls: ['../css.component.scss']
})
export class CartsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: any = new Subject();

  closeResult: string
  files: any[]
  data: any = []
  category_type = null
  insert: any[] = [
    {
      do: null,
      group_id: null,
      brand_id: null,
      category_id: null,
      sub_category_id: null,
      discount_type: "N",
      name: null,
      image: null,
      seq: null,
      created_by: null,
      created_date: null,
      updated_by: null,
      updated_date: null
    }
  ]
  img: any = null
  type_product: any[] = []
  category: any[]
  brand: any[]
  select_brand: any
  select_data: any
  select_model: any[]
  sub_category: any[] = []
  select_category: any[] = []
  select_product: any[]
  product: any
  group: any[]
  id: any
  year: any[]
  name: string
  select_product_model: any[]
  product_model: any[]
  group_product_price: any[]
  purchase_order_id: any
  customer_id: any
  status: any
  case: any = 1;
  category_id = '110'

  setting_code: any[];
  customer: any[];

  sum_total = {
    total_price: 0,
    total_discount: 0,
    total_vat: 0,
    total_net: 0
  }

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
    private router: Router,
    private route: ActivatedRoute

  ) { }

  async ngOnInit() {
    // this.id = this.route.snapshot.queryParams['id']
    // this.group = this.route.snapshot.queryParams['gp']
    // this.purchase_order_id = this.route.snapshot.queryParams['po']
    // this.customer_id = this.route.snapshot.queryParams['ct']

    this.id = this.route.snapshot.queryParams['id']
    this.name = this.route.snapshot.queryParams['name']
    this.case = this.route.snapshot.queryParams['case']

    await this.dataService.isLogIn()
    this.customer_id = this.dataService.login[0].customer_id

    await this.get_setting_code(this.category_id)
    await this.get_customer()
    await this.get_data()

    this.dtOptions = {
      pagingType: 'full_numbers',
    };
    this.dtTrigger.next();
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  //Not Order
  async valueSelected() {
    if (this.case == 2) this.data = this.data
    else this.data = this.data.filter(
      item => item.purchase_order_id == null
    )
    this.cal_total()
  }

  cal_total() {
    this.sum_total = {
      total_price: 0,
      total_discount: 0,
      total_vat: 0,
      total_net: 0
    }

    for (let i = 0; i < this.data.length; i++) {
      this.sum_total.total_price += parseFloat(this.data[i].price) || 0
      this.sum_total.total_discount += parseFloat(this.data[i].discount_actually_baht) || 0
      this.sum_total.total_net += parseFloat(this.data[i].net_price) || 0
    }
  }


  async get_setting_code(e) {
    await this.dataService.get_data({ do: 'get_setting_code', category_id: e })
    this.setting_code = this.dataService.data
  }

  async get_customer() {
    await this.dataService.get_data({ do: 'get_customer', id: this.customer_id })
    this.customer = this.dataService.data
    if (this.customer[0] == 0) {
      this.customer = []
    }
    // console.log('this.customer : ', this.customer)
  }

  async get_data() {
    await this.dataService.get_data({ do: 'get_purchase_carts_customer', id: this.customer_id, po: this.id })
    this.data = this.dataService.data
    // console.log('this.data ')
    // console.log(this.data)


    if (this.data[0] == 0) {
      this.data = []
      return
    }
    await this.valueSelected();
  }

  async onUpdate() {
    console.log('Update')
    let input = {
      do: 'add_purchase_order_customer',
      customer_id: this.customer_id,
      category_id: this.category_id,
      code: this.setting_code[0].code + '-' + this.setting_code[0].number,
      total_price: 0,
      discount: 0,
      net_price: 0,
      total_qty: 0,
      // id: this.purchase_order_id,
      price: 0
    }
    for (let i = 0; i < this.data.length; i++) {
      input.price += parseFloat(this.data[i].priceperunit) || 0
      input.total_price += parseFloat(this.data[i].price) || 0
      input.discount += parseFloat(this.data[i].discount_actually_baht) || 0
      input.net_price += parseFloat(this.data[i].net_price) || 0
      input.total_qty += parseFloat(this.data[i].qty) || 0
    }

    await this.dataService.set_data(input)
    // this.get_data()
    window.location.reload();
  }

  async onSave() {
    console.log('Save')
    if (this.data.length <= 0) {
      alert("ไม่สามารถทำรายการได้ เนื่องจากไม่มีรายการสินค้าในตะกร้า")
      return;
    }

    if (confirm('ต้องการบันทึกหรือไม่?')) {
      await this.onUpdate();
    }
  }
  async onDel(e) {
    if (confirm('ต้องการจะลบหรือไม่?')) {
      this.insert[0] = this.data[e]
      this.insert[0].do = 'del_purchase_carts'
      await this.dataService.set_data(this.insert[0])
      // await this.get_data();
      window.location.reload();
    }
  }


}
